/** @jsx jsx */
import { jsx } from "theme-ui";

import DefaultLayout from "../../layouts/default";
import AnnouncementsBoard from "../../components/Trainer/AnnouncementsBoard";

function AbhyasiRegistrationFormPage() {
  return (
    <DefaultLayout>
      <AnnouncementsBoard />
      <div sx={{ mt: 4, p: 2 }}>
        <h4>Preceptor Resources</h4>
        All talks given by Daaji for Preceptors available at:{" "}
        <a href="https://preceptor.heartfulness.org/en/preceptor/clarifications-and-talks/kanha-20230426">
          https://preceptor.heartfulness.org/en/preceptor/video
        </a>
      </div>
    </DefaultLayout>
  );
}

export default AbhyasiRegistrationFormPage;
