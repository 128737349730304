/** @jsx jsx */
import { jsx, Spinner, Text } from "theme-ui";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";

import { get } from "sites-common/utils/lodash";
import { doFormatShortDate } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";

const collection = `PreceptorAnnouncements`;
const storeAs = "pcptA2i";
const listenerSettings1 = {
  collection,
  orderBy: [["date_added", "desc"]],
  storeAs,
};

function AnnouncementsBoard() {
  const firestore = useFirestore();
  const announcements = useSelector((state) =>
    get(state.firestore.ordered, [storeAs], undefined)
  );
  const [limit] = useState(4);

  const { srcmProfile } = useAuth();
  const { uid } = srcmProfile || {};

  useEffect(() => {
    if (uid) {
      const listenerSettings = { ...listenerSettings1, limit };
      firestore.setListener(listenerSettings);
      return function cleanup() {
        firestore.unsetListener(listenerSettings);
      };
    }
    return null;
  }, [firestore, limit, uid]);

  return (
    <div>
      {announcements === undefined && <Spinner />}
      {announcements !== undefined && (
        <div sx={{ bg: "white", p: 3 }}>
          {announcements.length > 0 && (
            <Text variant="header"> Trainers Section </Text>
          )}
          {announcements.map((a) => {
            return (
              <div>
                <div sx={{ mb: 3 }}>
                  <Text variant="description">
                    {doFormatShortDate(a.date_added)}
                  </Text>
                </div>
                {a.type === "html" ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<div>${a.note}</div>`,
                    }}
                  />
                ) : (
                  <Text variant="sectionTitle">{a.note}</Text>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

AnnouncementsBoard.propTypes = {};

export default AnnouncementsBoard;
